<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tickets">Tickets</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tickets/import">Import</a>
    </div>
    <div class="row">
      <div class="col-md-12 pt-3 px-4" v-if="current === 0 && (!rows || rows.length === 0)">
        <div class="text-center m-5 p-5">
          <small>
            <div class="attachment-list new-ticket mt-3 pt-5">
              <input class="d-none" type="file" ref="file" v-on:change="onFileChange" accept=".csv" />
              <div class="attachment-item" v-on:click="$refs.file.click()" style="transform: scale(1.5)">
                <i class="col-2 fas fa-plus text-escalated"></i>
              </div>
            </div>
            <br>
            <i class="fas fa-info-circle"></i>&nbsp;&nbsp;Upload a CSV file first.
            <p>
              Download a copy of the format <span class="text-primary cursor-pointer" v-on:click="downloadTemplate">here</span>
            </p>
          </small>
        </div>
      </div>
      <div class="col-md-12 pt-3 px-4" v-else-if="current === 0">
        <div class="btn btn-sm btn-primary border-0 float-right" v-on:click="importTickets">
          <i class="fas fa-upload"></i>&nbsp;&nbsp;&nbsp;Import tickets
        </div>
        <div class="float-right">&nbsp;&nbsp;</div>
        <input class="d-none" type="file" ref="file" v-on:change="onFileChange" accept=".csv" />
        <div class="btn btn-sm btn-primary border-0 float-right" v-on:click="$refs.file.click()">
          <i class="fas fa-undo"></i>&nbsp;&nbsp;&nbsp;Change file
        </div>
        <p>
          You have selected 1 file - {{filename}}<br>
          <small v-bind:class="rows.filter(r => validateRecord(r)).length === rows.length ? `text-grass` : `text-warm`">
            {{rows.filter(r => validateRecord(r)).length}}/{{rows.length}} valid records
          </small>
        </p>
        <br>
        <div class="" style="min-height: 80vh; overflowX: scroll">
          <table class="table" style="width: auto">
            <tr>
              <th class="p-2 text-header" style="min-width: 5px"></th>
              <th class="p-2 text-header" style="min-width: 150px">Issue Title</th>
              <th class="p-2 text-header" style="min-width: 120px">Description</th>
              <th class="p-2 text-header" style="min-width: 200px">Client</th>
              <th class="p-2 text-header" style="min-width: 230px">Customer</th>
              <th class="p-2 text-header" style="min-width: 230px">Issue Classification</th>
              <th class="p-2 text-header" style="min-width: 200px">Status</th>
              <th class="p-2 text-header" style="min-width: 250px">Severity</th>
              <th class="p-2 text-header" style="min-width: 350px">Project</th>
              <th class="p-2 text-header" style="min-width: 230px">Manager</th>
              <th class="p-2 text-header" style="min-width: 230px">Agent</th>
              <th class="p-2 text-header" style="min-width: 250px">Agent Severity</th>
            </tr>
            <tr v-for="(row, i) in rows" v-bind:key="row.key">
              <td class="p-1">
                <h4>
                  <i class="fas fa-check-circle text-grass" v-if="validateRecord(row)"></i>
                  <i class="fas fa-info-circle text-warm" v-else></i>
                </h4>
              </td>
              <td class="p-2">
                <small>{{row.issue}}</small>
              </td>
              <td class="p-2">
                <div class="btn w-100 btn-sm border px-3" v-on:click="openDescription(i)">
                  <small>View</small>
                </div>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.client"
                  placeholder="Assign client"
                  :options="options.clients"
                  :isError="!row.client.value"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.customer"
                  placeholder="Assign customer"
                  :options="filterCustomers(row.client)"
                  :isError="!row.customer.value"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.issueClassification"
                  placeholder="Assign classification"
                  :options="options.issueClassifications"
                  class="border-warm"
                  :isError="!row.issueClassification.value"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.status"
                  placeholder="Assign status"
                  :options="options.statuses"
                  :isError="!row.status.value"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.priority"
                  placeholder="Assign severity"
                  :options="options.priorities"
                  :isError="!row.priority.value"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.project"
                  placeholder="Assign project"
                  :options="filterProjects(row.status, row.client)"
                  :isError="!row.project.value && row.status.value !== 'pending'"
                  :isDisabled="row.status.value === 'pending'"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.manager"
                  placeholder="Assign manager"
                  :options="filterManagers(row.status, row.client, row.project)"
                  :isError="!row.manager.value && row.status.value !== 'pending'"
                  :isDisabled="row.status.value === 'pending'"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.agent"
                  placeholder="Assign agent"
                  :options="filterAgents(row.status, row.client, row.project)"
                  :isError="!row.agent.value && row.status.value !== 'pending'"
                  :isDisabled="row.status.value === 'pending'"
                >
                </ModelSelect>
              </td>
              <td class="p-2">
                <ModelSelect
                  v-model="row.agentPriority"
                  placeholder="Assign agent severity"
                  :options="filterAgentPriorities(row.status)"
                  :isError="!row.agentPriority.value && row.status.value !== 'pending'"
                  :isDisabled="row.status.value === 'pending'"
                >
                </ModelSelect>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-md-12 pt-3 px-4" v-else-if="current === 1">
        <div class="text-center m-5 p-5">
          <br><br>
          <div class="mt-5 bg-primary-dark progress-holder">
            <div class="bg-primary-default progress-bar" v-bind:style="{ width: `calc(${importProgress}% - 5px)` }"></div>
          </div>
          <br>
          <small v-on:click="addProgressCount">
            <i class="fas fa-spinner animate-spin"></i>&nbsp;&nbsp;Please wait while we import your tickets...
          </small>
        </div>
      </div>
      <div class="col-md-12 pt-3 px-4" v-else-if="current === 2">
        <div class="text-center m-5 p-5">
          <h1 class="text-grass mb-3 pt-5 pb-3" style="transform: scale(1.5)">
            <i class="fas fa-check-circle"></i>
          </h1>
          <p>
            Import successful.
          </p>
          <br>
          <a href="/tickets/import" class="btn btn-sm btn-primary">Import another file</a>&nbsp;&nbsp;
          <a href="/tickets" class="btn btn-sm btn-primary">View tickets</a>
        </div>
      </div>
    </div>
    <modal name="ticket-description" :transition="`fade`" :width="`500px`" :height="`auto`" :scrollable="true">
      <div class="p-3">
        <!-- <editor
          v-model="rows[selectedRecord].description"
          v-if="selectedRecord !== null && rows.length > 0"
          theme="snow"
        >
        </editor> -->
        <div
          class="issue-description m-3 p-0"
          v-html="imgViewerReady(rows[selectedRecord].description)"
          v-if="selectedRecord !== null && rows.length > 0"
        >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        options: {
          clients: [],
          issueClassifications: this.$issueClassifications.filter(r => r.record.isLocked && r.record.forms.filter(q => !q.required).length === 0),
          priorities: this.$priorities,
          statuses: this.$statuses,
          users: [],
          agents: [],
          managers: [],
          customers: [],
          projects: []
        },
        content: '',
        filename: '',
        selectedRecord: null,
        description: '',
        rows: [],
        records: [],
        current: 0,
        processCount: 0,
        gitLabId: null
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getUsers(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent,Customer`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.users = response.data.users.map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.options.agents = response.data.users.filter(r => r.roles === "Agent" || (r.roles === "Manager" && r.isAgent)).map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.options.managers = response.data.users.filter(r => r.roles === "Manager").map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.options.customers = response.data.users.filter(r => r.roles === "Customer").map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getProjects(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/projects`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.projects = response.data.projects.filter(r => !r.disabled).map(r => {
            return { key: r._id, text: r.gitLab.title, value: r._id, record: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.filename = files[0].name
        this.createInput(files[0]);
      },
      createInput(file) {
        this.loader(true)
        let promise = new Promise((resolve, reject) => {
          var reader = new FileReader();
          var vm = this;
          reader.onload = e => {
            resolve((vm.content = reader.result));
            e
          };
          reader.readAsText(file);
          reject
        });

        promise.then(
          result => {
            this.content = result.split("\n").map(r => this.$csvToArray(r))
            if(this.content.length < 2){
              this.$alertify({
                group: 'notification',
                title: 'Import tickets',
                type: 'warn',
                text: `There are no records on this CSV.`
              })
              return 0
            }
            this.validateFile(this.content)
          },
          error => {
            console.log(error);
          }
        )
        this.loader(false)
      },
      validateFile: function(content){
        this.loader(true)
        let headers = [ "issue", "description", "client", "customer", "classification", "status", "severity", "agent", "manager", "project"]
        let csv_headers = content ? content[0] : null || []
        let obj = {}
        this.rows = []
        if(headers.filter(v => !csv_headers.includes(v)).length > 0){
          this.$alertify({
            group: 'notification',
            title: 'Import tickets',
            type: 'warn',
            text: `There are missing fields on the CSV - ${headers.filter(v => !csv_headers.includes(v)).join(', ')}`
          })
          this.loader(false)
          return 0
        }
        else{
          for(var i = 1; i < content.length; i++){
            obj = {}
            for(var j = 0; j < csv_headers.length; j++){
              if(headers.includes(csv_headers[j]))
                obj[csv_headers[j]] = `${content[i][j]}`.trim().toLowerCase()
            }
            this.rows.push(obj)
          }
          let tempClient, tempProject, tempStatus = {}
          this.rows = this.rows.filter(r => r.issue !== '' && r.description !== '').map((record, i) => {
            tempClient = this.options.clients.find(r => r.record.code && record.client === r.record.code.toLowerCase()) || {}
            tempStatus = this.options.statuses.find(r => record.status === r.value.toLowerCase()) || {}
            tempProject = this.filterProjects(tempStatus, tempClient).find(r => record.project === r.record.gitLab.url.toLowerCase() && tempClient && tempClient.value === r.record.clientId) || {}
            return {
              key: i,
              issue: record.issue,
              description: record.description,
              client: tempClient,
              customer: !tempClient.value ? {} : this.filterCustomers(tempClient).find(r => record.customer === r.record.email.toLowerCase()) || {},
              issueClassification: this.options.issueClassifications.find(r => record.classification === r.record.code.toLowerCase()) || {},
              status: tempStatus,
              priority: this.options.priorities.find(r => record.severity === r.code.toLowerCase()) || {},
              agentPriority: tempStatus.value === "pending" ? {} : this.options.priorities.find(r => record.severity === r.code.toLowerCase()) || {},
              project: tempStatus.value === "pending" ? {} : tempProject,
              agent: tempStatus.value === "pending" ? {} : this.filterAgents(tempStatus, tempClient, tempProject).find(r => record.agent === r.record.email.toLowerCase()) || {},
              manager: tempStatus.value === "pending" ? {} : this.filterManagers(tempStatus, tempClient, tempProject).find(r => record.manager === r.record.email.toLowerCase()) || {}
            }
          })
          if(this.rows.length === 0){
            this.$alertify({
              group: 'notification',
              title: 'Import tickets',
              type: 'warn',
              text: `There are no records on this CSV.`
            })
            this.loader(false)
            return 0
          }
        }
        this.loader(false)
      },
      validateRecord: function(record){
        if(
          record.client.value &&
          record.customer.value &&
          record.issueClassification.value &&
          record.status.value &&
          record.priority.value &&
          record.issue.trim() !== "" &&
          record.description.trim() !== "" &&
          (
            (record.status.value !== 'pending' && record.project.value && record.agent.value && record.manager.value && record.agentPriority.value) ||
            record.status.value === 'pending'
          )
        )
          return true
        else
          return false
      },
      filterProjects: function(status, client){
        if(status && status.value !== "pending" && client && client.value)
          return this.options.projects.filter(r => r.record.clientId === client.value)
        else
          return []
      },
      filterCustomers: function(client){
        if(client && client.value)
          return this.options.customers.filter(r => r.record.client._id === client.value)
        else
          return []
      },
      filterAgents: function(status, client, project){
        if(status && status.value !== "pending" && client && client.value && project && project.value && client.value === project.record.clientId)
          return this.options.agents.filter(r => r.record.bunit.map(r => r._id).includes(project.record.bunit._id))
        else
          return []
      },
      filterManagers: function(status, client, project){
        if(status && status.value !== "pending" && client && client.value && project && project.value && client.value === project.record.clientId)
          return this.options.managers.filter(r => r.record.bunit.map(r => r._id).includes(project.record.bunit._id))
        else
          return []
      },
      filterAgentPriorities: function(status){
        if(status && status.value !== "pending")
          return this.options.priorities
        else
          return []
      },
      openDescription: function(i){
        this.selectedRecord = i
        this.$modal.toggle('ticket-description')
      },
      importTickets: async function(){
        let next = this.validRecords.length === this.rows.length
        if(this.validRecords.length === 0){
          this.$alertify({
            group: 'notification',
            title: `Import Tickets`,
            type: 'warning',
            text: `There are no valid records. Please assign the right values or select another file.`
          })
          return 0
        }
        if(!next){
          // show confirm modal
          this.$alertify({
            group: 'notification',
            title: `Import Tickets`,
            type: 'warning',
            text: `There are ${this.invalidRecords.length} invalid record/s. Do you wish to continue? (Invalid records will not be included)`
          })
          next = true
        }
        if(next){
          this.current += 1
          let row = {}, results = []
          for(let i = 0; i < this.validRecords.length; i++){
            row = this.validRecords[i]
            results.push(await this.submitNewTicket(row, {
              issue: row.issue,
              description: row.description,
              client: row.client.record,
              issueClassification: row.issueClassification.value,
              priority: row.priority.value,
              forms: null,
              settings: null,
              isActive: true,
              createdBy: this.user,
              customer: row.customer.record,
              status: 'pending',
              attachments: []
            }))
            this.addProgressCount()
          }
          this.$alertify({
            group: 'notification',
            title: 'Import tickets',
            type: results.length === results.filter(r => r.success).length ? 'success' : 'info',
            text: results.length === results.filter(r => r.success).length ? 'All tickets were successfully imported' :
                  `A total of ${results.filter(r => !r.success).length} errors were encountered during importation`
          })
          this.addProgressCount()
        }
      },
      submitNewTicket: async function(record, ticket){
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets/new`,
              {ticket: ticket},
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data && response.data.ticket && response.data.ticket._id)
            if(record.status.value !== 'pending')
              return await this.updateTicket(record, response.data.ticket)
            else
              return {
                success: true,
                error: null,
                ticket: response.data.ticket
              }
          else
            return {
              success: false,
              error: 'The backend has encountered an error while saving the ticket',
              ticket: {}
            }
        } catch(e) {
          return {
            success: false,
            error: 'The backend has encountered an error while saving the ticket',
            ticket: {}
          }
        }
      },
      updateTicket: async function(record, ticket){
        let data = {
          status: record.status.value,
          agent: record.agent.record,
          manager: record.manager.record,
          agentPriority: record.agentPriority.value,
          project: record.project.record
        }
        if (data.status === 'escalated'){
          if(await this.createGitTicket(data, ticket))
            data.gitLabId = this.gitLabId
          else
            return {
              success: false,
              error: 'There was an error while creating the Gitlab ticket. Ticket status has been revereted back to pending.',
              ticket: {}
            }
        }
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/ticket`,
            {
              ticket: data,
              _ticket: ticket
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data && response.data.ticket && response.data.ticket._id)
            return {
              success: true,
              error: null,
              ticket: response.data.ticket
            }
        else
          return {
            success: false,
            error: 'The backend has encountered an error while saving the ticket',
            ticket: {}
          }
        } catch(e) {
          return {
            success: false,
            error: 'The backend has encountered an error while saving the ticket',
            ticket: {}
          }
        }
      },
      createGitTicket: async function(record, ticket){
        const project = record.project
        const agent = record.agent
        this.gitLabId = false
        if(!project || !agent)
          return false
        await this.$markDownImages(ticket.description, {id: project.gitLab.id, token: agent.gitlabPersonalToken})
        .then(async description => {
          let data = {
            title: `[${ticket.ticketNumber}] ${ticket.issue}`,
            description: description,
            labels: `From ITS,${ticket.issueClassification}`
          }
          try{
            const response = await this.$http.post(
              `${this.$gitEndpoint}/projects/${project.gitLab.id}/issues`,
              data,
              {
                headers: {
                  'Authorization': `Bearer ${agent.gitlabPersonalToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            this.gitLabId = response.data.iid
          } catch(err) {
            console.log(err)
          }
        }).catch(e => console.log(e))
        return this.gitLabId
      },
      back: function(){
        this.current -= 1
      },
      addProgressCount: function(){
        this.processCount += 1
        if(this.validRecords.length < this.processCount)
          this.current += 1
      },
      downloadTemplate: function(){
        this.$downloadFile('csv', 'issue,description,client,customer,classification,status,severity,project,manager,agent,agentSeverity', 'ITS_import_tickets_template')
      },
      imgViewerReady: function(html){
        return html ? html.replace('<img', '<img class="ticket-description-img"') : ''
      }
    },
    computed: {
      importProgress: function () {
        if(!this.validRecords || this.validRecords.length === 0)
          return 0
        else
          return this.processCount / this.validRecords.length * 100
      },
      validRecords: function() {
        return this.rows.filter(r => this.validateRecord(r))
      },
      invalidRecords: function() {
        return this.rows.filter(r => this.validateRecord(r))
      }
    },
    mounted: function() {
      document.title = `Import tickets | iRipple Helpdesk`
      this.getClients()
      this.getUsers()
      this.getProjects()
    }
  }
</script>
